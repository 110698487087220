import "./index.scss";
function Nav_menu_list() {
    return (
        <div className="nav_menu_list">
            <a className="nav_menu_item" href="https://www.facebook.com/NTUTBlockchain/">Facebook</a>
            <a className="nav_menu_item" href="https://forms.gle/wUgigXMoW12vi9nv8">報名茶會</a>
            <a className="nav_menu_item" href="https://forms.gle/fbDvcPQkeDDKgzZJ8">加入我們！</a>
        </div>
    );
}
export default Nav_menu_list;
