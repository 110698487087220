import "./index.scss";
import {
  a,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  i,
  j,
  k,
  l,
  m,
  n,
  o,
  p,
  q,
  r,
  s,
} from "../../assets/images/lecture_images";
function End_lecture() {
  return (
    <div className="end_lecture_container">
      <div className="title">
        <h1>以往課程</h1>
        <h1>Previous Lecture</h1>
      </div>
      <div className="end_lecture_photos">
        <img src={a} alt="" />
        <img src={b} alt="" />
        <img src={c} alt="" />
        <img src={d} alt="" />
        <img src={e} alt="" />
        <img src={f} alt="" />
        <img src={g} alt="" />
        <img src={h} alt="" />

        <img src={i} alt="" />
        <img src={j} alt="" />
        <img src={k} alt="" />
        <img src={l} alt="" />
        <img src={m} alt="" />
        <img src={n} alt="" />
        <img src={o} alt="" />
        <img src={p} alt="" />
        <img src={q} alt="" />
        <img src={r} alt="" />
        <img src={s} alt="" />
      </div>
    </div>
  );
}

export default End_lecture;
